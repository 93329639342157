import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentCallDetailsService {
  private dataKey = 'call-details';
  private dataSubject: BehaviorSubject<any>;

  constructor() {
    const storedData = sessionStorage.getItem(this.dataKey);
    this.dataSubject = new BehaviorSubject<any>(storedData ? JSON.parse(storedData) : null);
  }

  getData() {
    return this.dataSubject.asObservable();
  }

  updateData(newData: any) {
    this.dataSubject.next(newData);
    sessionStorage.setItem(this.dataKey, JSON.stringify(newData));
  }

  removeData() {
    this.dataSubject.next(null);
    sessionStorage.removeItem(this.dataKey);
  }
}
